/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 75;
  }
  
  .modal-content {
    background: white;
    padding: 10px;
    border-radius: 8px;
    position: relative;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-image {
    max-width: 80%;
    max-height: 80%;
    height: auto;
  }
  
  .modal-open {
    overflow: hidden;
  }